import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import './bootstrap_custom.js';
import "chartkick/chart.js";
import "trix";
import "@rails/actiontext";
import flatpickr from "flatpickr";
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import "./src/jquery";
import "./src/jquery-ui";
import "./src/imageViewer";
import './controllers';

// Initialize Turbo and ActiveStorage
ActiveStorage.start();

// Initialize Flatpickr with different configurations
document.addEventListener('turbo:load', () => {
  flatpickr(".datepicker", {
    locale: Russian,
    allowInput: true,
    dateFormat: 'Y-m-d'
  });

  flatpickr(".datetimepicker", {
    locale: Russian,
    dateFormat: 'Y-m-d H:i',
    enableTime: true,
    time_24hr: true,
    minTime: '09:00',
    maxTime: '18:30',
    stepping: 30
  });

  flatpickr(".timepicker", {
    dateFormat: 'H:i',
    noCalendar: true,
    enableTime: true,
    time_24hr: true,
    minTime: '09:00',
    maxTime: '18:30',
    allowInput: true,
    minuteIncrement: 30
  });
});
