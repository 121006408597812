import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  //connect() {
    //this.outputTarget.textContent = 'Hello, Stimulus!'
  //}

  toggle(event) {
    event.preventDefault()
    this.formTarget.classList.toggle("d-none")
  }
}
