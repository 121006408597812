import * as bootstrap from "bootstrap";

// Function to initialize Bootstrap popovers
const initializePopovers = () => {
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));
};

// Function to handle form validation
const handleFormValidation = () => {
  // Custom Bootstrap validation styles
  // Example starter JavaScript for disabling form submissions if there are invalid fields
  (() => {
    'use strict';

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll('.needs-validation');

    // Loop over them and prevent submission
    Array.from(forms).forEach(form => {
      form.addEventListener('submit', event => {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }

        form.classList.add('was-validated');
      }, false);
    });
  })();
};


// Initialize everything when the document is loaded
const init = () => {
  initializePopovers();
  handleFormValidation();
};

// Listen for Turbo Load event
document.addEventListener('turbo:load', init);

// Optionally, listen for DOMContentLoaded if not using Turbo
document.addEventListener('DOMContentLoaded', init);
