import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import Viewer from 'viewerjs';

document.addEventListener('turbo:load', () => {
  // Ensure the grid element is available
  var grid = document.querySelector('.imageViewer');
  
  if (!grid) {
    console.error('Element .imageViewer not found');
    return;
  }

  // Initialize Masonry after ensuring the grid element exists
  var msnry = new Masonry(grid, {
    itemSelector: '.imageViewer-item',
    columnWidth: 100,
    percentPosition: true,
    horizontalOrder: true
  });

  // Initialize Viewer.js after Masonry layout is complete
  msnry.once('layoutComplete', function() {
    console.log('Masonry layout done');

    const viewer = new Viewer(document.querySelector('.imageViewer'), {
      url: 'data-original',
      title: false,
      transition: false,
      toolbar: {
        zoomIn: 4,
        zoomOut: 4,
        oneToOne: 4,
        reset: 4,
        prev: 4,
        play: false,
        next: 4,
        rotateLeft: 4,
        rotateRight: 4,
        flipHorizontal: 4,
        flipVertical: 4,
      }
    });
  });

  // Ensure imagesLoaded is called before Masonry layout
  imagesLoaded(grid).on('progress', function() {
    msnry.layout();
  });
});
