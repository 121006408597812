import { Controller } from "@hotwired/stimulus"
import Lightbox from 'stimulus-lightbox'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgRotate from 'lightgallery/plugins/rotate'

export default class extends Lightbox {
  connect() {
    super.connect()
    console.log('Do what you want here.')

    // Get the lightgallery instance
    this.lightGallery

    // Default options for every lightboxes.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {
      plugins: [lgZoom, lgThumbnail, lgRotate],
      speed: 500,
    }
  }
}